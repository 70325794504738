
import { Vue, Component } from "vue-property-decorator";
import { mdiCellphoneArrowDown } from "@mdi/js";
import { addAnalyticsEvent } from "@/plugins/firebase";
import { captureSentryException } from "@/plugins/sentry";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { isInStandaloneMode } from "@/plugins/utils";
import { Action } from "vuex-class";
@Component({
    components: { LoadingSpinner },
})
export default class UpdateAppButton extends Vue {
    notificationDialogOpen = false;
    updateIcon: string = mdiCellphoneArrowDown;
    isRegistering: boolean = false;
    @Action("setNavRoute") setNavRoute!: (route: string | null) => void;

    updateButtonClicked(event: Event) {
        event.preventDefault();
        addAnalyticsEvent("update_button_clicked");
    }

    closeDialog(event: Event) {
        event.preventDefault();
        this.notificationDialogOpen = false;
    }

    get platformName() {
        if (isInStandaloneMode()) {
            return "app";
        }
        return "website";
    }

    get appName() {
        return process.env.VUE_APP_APP_NAME;
    }

    forceUpdate() {
        this.isRegistering = true;
        addAnalyticsEvent("force_update_triggered");
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .getRegistrations()
                .then((registrations) => {
                    const promises = Array<Promise<boolean>>();
                    registrations.forEach((registration) => {
                        promises.push(registration.unregister());
                    });
                    return Promise.all(promises);
                })
                .then(() =>
                    caches
                        .keys()
                        .then((keys) => keys.map((key) => caches.delete(key)))
                )
                .then(() => {
                    this.setNavRoute(this.$route.name ?? null);
                    this.$root.$emit(
                        this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                        `Update successful! The ${this.platformName} will reload in a few seconds.`
                    );

                    setTimeout(() => {
                        this.notificationDialogOpen = false;
                        this.isRegistering = false;
                        window.location.reload();
                    }, 4000);
                })
                .catch(console.error);
        } else {
            captureSentryException(
                new Error(
                    "cannot force update because navigator doesn't have serviceWorker"
                )
            );
        }
    }
}
