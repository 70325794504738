
import { Vue, Component } from "vue-property-decorator";
import { mdiCheckCircle, mdiAlertCircle, mdiInformation } from "@mdi/js";

export type NotificationMessageColor = "success" | "warning" | "error" | "info";

@Component
export default class NotificationMessage extends Vue {
    successIcon: string = mdiCheckCircle;
    warningIcon: string = mdiAlertCircle;
    infoIcon: string = mdiInformation;
    snackbarShow: boolean = false;
    snackbarColor: NotificationMessageColor = "success";
    snackBarMessage = "";

    mounted() {
        this.$root.$on(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            this.handleError
        );
        this.$root.$on(
            this.$constants.NOTIFICATION_EVENTS.SUCCESS,
            this.handleSuccessNotification
        );

        this.$root.$on(
            this.$constants.NOTIFICATION_EVENTS.INFO,
            this.handleInfoNotification
        );
    }
    handleInfoNotification(message: string): void {
        this.snackBarMessage = message;
        this.snackbarColor = "info";
        this.snackbarShow = true;
    }
    handleSuccessNotification(message: string): void {
        this.snackBarMessage = message;
        this.snackbarColor = "success";
        this.snackbarShow = true;
    }
    handleError(message: string): void {
        this.snackBarMessage =
            message ||
            "We ran into an error while performing the request. Please try again later";
        this.snackbarColor = "error";
        this.snackbarShow = true;
    }
}
