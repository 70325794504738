
import { Vue, Component } from "vue-property-decorator";
import { addAnalyticsEvent } from "@/plugins/firebase";
import { isAndroid } from "@/plugins/utils";
@Component
export default class InstallButton extends Vue {
    routeInstall(event: Event) {
        event.preventDefault();
        addAnalyticsEvent("install_button_clicked");
        if (this.$route.name != this.$constants.ROUTE_NAMES.INSTALL_INDEX) {
            this.$router.push({
                name: this.$constants.ROUTE_NAMES.INSTALL_INDEX,
            });
            return;
        }
    }

    get android(): boolean {
        return isAndroid();
    }
}
